import React from 'react'
import SocialNetwork from '../SocialNetwork'

const Footer = (props) => {
  const { copyright, fbaccount, twaccount, ldaccount, intaccount, reddaccount } = props

  return (
    <footer className='footer'>
      <div className='container'>
        <div className='content has-text-centered'>
          <p>
            {copyright}
          </p>
          <p>Powered by <a target='_blank' rel='noopener noreferrer' href='https://www.venehsoftw.site'>VeneHsoftw</a> using <a target='_blank' rel='noopener noreferrer' href='https://www.gatsbyjs.com/'>Gatsby</a></p>
        </div>
        <div className='content has-text-centered'>
          <p>
            <SocialNetwork
              fbaccount={fbaccount}
              twaccount={twaccount}
              ldaccount={ldaccount}
              intaccount={intaccount}
              reddaccount={reddaccount}
            />
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
