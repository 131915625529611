import React from 'react'
// import {
//  FacebookShareButton,
//  LinkedinShareButton,
//  TwitterShareButton,
//  TelegramShareButton,
//  RedditShareButton,
//  FacebookIcon,
//  TwitterIcon,
//  TelegramIcon,
//  LinkedinIcon,
//  RedditIcon,
// } from 'react-share'
import { SocialIcon } from 'react-social-icons'
import './styles.sass'

const SocialNetwork = (props) => {
  const { mobile, fbaccount, twaccount, ldaccount, intaccount, reddaccount } = props
  // const realPrefix = pathPrefix === '/' ? '' : pathPrefix
  // const url = siteUrl + realPrefix + slug

  const heighticonSize = mobile ? 25 : 100
  const widhticonSize = mobile ? 25 : 100
  // FacebookShareCount,
  //  RedditShareCount,
  //
  // const filter = count => (count > 0 ? count : '')
  // <RedditShareCount url={url}>
  //        {count => <div className='share-count'>{filter(count)}</div>}
  // </RedditShareCount>
  // <FacebookShareCount url={url}>
  //        {count => <div className='share-count'>{filter(count)}</div>}
  // </FacebookShareCount>
  //   <RedditShareButton url={reddaccount}>
  //      <RedditIcon round size={iconSize} />
  //    </RedditShareButton>
  //    <TwitterShareButton url={twaccount}>
  //      <TwitterIcon round size={iconSize} />
  //    </TwitterShareButton>
  //    <FacebookShareButton url={fbaccount}>
  //      <FacebookIcon round size={iconSize} />
  //    </FacebookShareButton>
  //    <LinkedinShareButton
  //      url={ldaccount}
  //    >
  //      <LinkedinIcon round size={iconSize} />
  //    </LinkedinShareButton>
  //    <TelegramShareButton url={intaccount}>
  //      <TelegramIcon round size={iconSize} />
  //    </TelegramShareButton>

  return (
    <div className='social-links'>
      <SocialIcon url={fbaccount} network='facebook' target='_blank' rel='noreferrer' />
      <SocialIcon url={twaccount} network='twitter' target='_blank' rel='noreferrer' />
      <SocialIcon url={ldaccount} network='linkedin' target='_blank' rel='noreferrer' />
      <SocialIcon url={intaccount} network='instagram' target='_blank' rel='noreferrer' />
      <SocialIcon url={reddaccount} network='reddit' target='_blank' rel='noreferrer' />
    </div>
  )
}
// <SocialIcon url={twaccount} network='twitter' style={{ height: { heighticonSize }, width: { widhticonSize } }} />
// <SocialIcon url={ldaccount} network='linkedin' style={{ height: { heighticonSize }, width: { widhticonSize } }} />
// <SocialIcon url={intaccount} network='instagram' style={{ height: { heighticonSize }, width: { widhticonSize } }} />
// <SocialIcon url={reddaccount} network='reddit' style={{ height: { heighticonSize }, width: { widhticonSize } }} />
export default SocialNetwork
