module.exports = {
  siteTitle: 'VeneHindustrial C.A', // Site title.
  siteTitleAlt: 'Business', // Alternative site title for SEO.
  siteLogo: '/icons/icon-512x512.png', // Logo used for SEO and manifest.
  siteUrl: 'https://www.venghindustrial.site', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: 'VeneHindustrial Business. The solution at you Industrial need.', // Website description used for RSS feeds/meta description tag.
  siteRss: 'https://www.venghindustrial.site/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTagManagerID: '', // GTM tracking ID.
  disqusShortname: 'venehindustrial-business', // Disqus shortname.
  userName: 'venehindustrial',
  userTwitter: 'venehindustrial',
  userLocation: 'Buenos Aires, Argentina',
  userDescription: '',
  copyright: 'Copyright © VeneHsoftw. All Rights Reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#0700d0', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
  FacebookAccount: 'https://www.facebook.com/venehindustrial/',
  TwitterAccount: 'https://twitter.com/venehindustrial/',
  LinkedInAccount: 'https://www.linkedin.com/company/venghindustrial/',
  InstagramAccount: 'https://www.instagram.com/venehindustrial/',
  RedditAccount: 'https://www.reddit.com/user/Venehindustrial/',
  cookieConsent: 'This website uses cookies which are used to collect anonymous information to improve your browsing experience and for analytics and metrics.', // @TODO Add GDPR Cookie Consent
}
